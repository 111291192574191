<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">Sites matchés</p>
    </div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-autocomplete
              v-model="partnerSelected"
              clearable
              :items="partnerList"
              label="Filtrer sur un partenaire"
              item-text="name"
              item-value="id"
              class="select-partner shrink"
              @change="getData"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="searchStr"
              label="Rechercher"
              @input="search"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="5"></v-col>
        </v-row>

        <div v-if="data.length !== 0">
          <v-data-table
            locale="fr-FR"
            :page="options.page"
            :pageCount="numberOfPages"
            :headers="headers"
            :items="data"
            :sort-by="'id'"
            :sort-desc="false"
            :options.sync="options"
            :server-items-length="options.total"
            :loading="loading"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [15, 25, 50],
            }"
            :items-per-page="options.itemsPerPage"
            dense
          >
            <template v-slot:item.identifier="{ item }">
              <div>
                <span v-if="item.identifier == item.comment">
                  {{ item.identifier }}
                </span>
                <span v-else> {{ item.identifier }} / {{ item.comment }} </span>
              </div>
            </template>
            <template v-slot:item.site_id="{ item }">
              <div v-if="item.site_id == '6'">Non matché</div>
              <div v-else>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      v-html="item.site_domain"
                    ></span>
                  </template>
                  <span>{{ item.site_name }} (id:{{ item.site_id }})</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:item.match_status="{ item }">
              <div v-if="item.match_status == 'unmatched'">Non matché</div>
              <div v-else-if="item.match_status == 'matched'">Matché</div>
              <div v-else-if="item.match_status == 'archived'">Archivé</div>
              <div v-else>Status indéfini</div>
            </template>
            <template v-slot:item.actions="{ item }">
              <div v-if="item.match_status == 'unmatched'">
                Problème: Status non prévu
              </div>
              <div v-else-if="item.match_status == 'matched'">
                <v-btn
                  :data-id="item.id"
                  v-on:click="unmatch"
                  color="primary"
                  x-small
                  elevation="4"
                  class="mx-2"
                >
                  Unmatch
                </v-btn>
                <v-btn
                  :data-id="item.id"
                  v-on:click="unmatchAndArchive"
                  color="primary"
                  x-small
                  elevation="4"
                  class="mx-2"
                >
                  Unmatch and archive
                </v-btn>
              </div>
              <div v-else-if="item.match_status == 'archived'">
                <v-btn
                  :data-id="item.id"
                  v-on:click="unmatch"
                  color="primary"
                  x-small
                  elevation="4"
                  class="mx-2"
                >
                  Reset
                </v-btn>
              </div>
              <div v-else>Problème: Status non prévu</div>
            </template>
          </v-data-table>
        </div>
        <div v-else>
          {{ noDataMessage }}
        </div>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="isSnackbarVisible"
      :timeout="2000"
      top
      :color="snackbarColor"
    >
      {{ snackbarText }}
    </v-snackbar>
    <v-dialog v-model="dialogConfirmVisible" max-width="800">
      <v-card>
        <v-card-title class="text-h5">
          {{ dialogConfirmTitle }}
        </v-card-title>
        <v-card-text>
          {{ dialogConfirmText }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="dialogConfirmCallback">
            Oui
          </v-btn>
          <v-btn color="green darken-1" @click="dialogConfirmVisible = false">
            Abandonner
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref } from "@vue/composition-api";
import { mdiPlus, mdiPencil, mdiDelete, mdiCancel } from "@mdi/js";
import axios from "@axios";

let defaultNoDataMessage = "Retrieving data ...";

export default {
  name: "SitesMatchedList",
  setup() {
    const isSnackbarVisible = ref(false);
    return {
      isSnackbarVisible,
    };
  },
  data() {
    return {
      partnerList: [],
      partnerSelected: null,
      searchStr: "",
      searchTimeout: null,
      expanded: [1],
      loading: true,
      searchStr: "",
      searchTimeout: null,
      numberOfPages: 0,
      options: {
        page: 1,
        itemsPerPage: 15,
        total: 0,
      },
      noDataMessage: defaultNoDataMessage,
      snackbarColor: "info", // info, primary, success, warning, error
      snackbarText: "",
      dialogConfirmVisible: false,
      dialogConfirmTitle: "",
      dialogConfirmText: "",
      dialogConfirmCallbackFunction: null,
      data: [],
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
        },
        {
          text: "Partner",
          value: "partner_name",
        },
        {
          text: "Account",
          value: "partner_account_name",
        },
        {
          text: "Identifier (/ Comment)",
          value: "identifier",
        },
        {
          text: "Site",
          value: "site_id",
        },
        {
          text: "Status",
          value: "match_status",
        },
        {
          value: "actions",
        },
      ],
      icons: {
        mdiPlus,
        mdiPencil,
        mdiDelete,
        mdiCancel,
      },
    };
  },
  methods: {
    async unmatch(e) {
      const id = e.currentTarget.getAttribute("data-id");
      console.log("id", id);
      const params = {
        id,
      };
      const response = await axios.post(`/partner_sites/unmatch`, params);
      console.log("unmatch response", response);
      if (response.status === 200) {
        console.log("Method save - response", response);
        this.showSnackBar("Unmatch successfully done", "success");
        this.getData();
      } else {
        this.showSnackBar("An error occurred.", "error");
      }
    },
    async unmatchAndArchive(e) {
      const id = e.currentTarget.getAttribute("data-id");
      console.log("id", id);
      const params = {
        id,
      };
      const response = await axios.post(
        `/partner_sites/unmatch-and-archive`,
        params
      );
      console.log("unmatch and archive response", response);
      if (response.status === 200) {
        console.log("Method save - response", response);
        this.showSnackBar("Unmatch and archive successfully done", "success");
        this.getData();
      } else {
        this.showSnackBar("An error occurred.", "error");
      }
    },
    async getData() {
      this.data = [];
      this.noDataMessage = defaultNoDataMessage;
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const queryParams = {
        partner_id: this.partnerSelected,
        search: this.searchStr,
        page: page - 1,
        limit: itemsPerPage,
        ...(sortBy && sortDesc && sortBy.length > 0 && sortDesc.length > 0
          ? {
              sort_by: sortBy[0],
              sort_desc: sortDesc[0],
            }
          : { sort_by: "id", sort_desc: false }),
      };
      const { data } = await axios.get("/partner_sites/matched", {
        params: queryParams,
      });
      this.loading = false;
      this.data = data.items;
      if (this.data.length === 0) {
        this.noDataMessage = "Aucune donnée trouvée";
      }
      this.options.total = data.total;
      this.numberOfPages = data.total / this.options.itemsPerPage;
    },
    search() {
      console.log("search", this.searchStr);
      if (this.searchTimeout) {
        console.log("clearTimeout");
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      setTimeout(() => {
        console.log("setTimeout end > search");
        this.getData();
      }, 500);
    },
    showSnackBar(text, color = "info") {
      this.snackbarColor = color; // info, primary, success, warning, error
      this.snackbarText = text;
      this.isSnackbarVisible = true;
    },
    dialogConfirmCallback() {
      this.dialogConfirmVisible = false;
      if (this.dialogConfirmCallbackFunction) {
        this.dialogConfirmCallbackFunction();
      }
      this.dialogConfirmCallbackFunction = null;
    },
    showDialogConfirm(title, text, callbackFunction) {
      this.dialogConfirmTitle = title;
      this.dialogConfirmText = text;
      this.dialogConfirmCallbackFunction = callbackFunction;
      this.dialogConfirmVisible = true;
    },
  },
  watch: {
    // options: {handler(){this.updateData();}, deep:true,},
    // individual properties to avoid refreshing when options.total updates
    "options.itemsPerPage"() {
      this.search();
    },
    "options.page"() {
      this.search();
    },
    // "options.sortBy"() {
    //   this.search();
    // },
    // "options.sortDesc"() {
    //   this.search();
    // },
    // "$store.state.c2c.siteList": function () {
    //   this.updateData();
    // },
  },
  async created() {
    this.partnerList = await this.$store.dispatch("premium/getPartnersList");
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
.app-content-container.mx-auto.h-full.app-content-container-boxed {
  max-width: 100%;
}
</style>

<style scoped>
.select-year {
  width: 30px;
}
.select-year {
  width: 250px;
}
th {
  text-transform: capitalize;
  font-size: 11px;
}
.cell span {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td.cell span {
  font-size: 0.8em;
}
td.cell.value_title span,
td.cell.value_meta_description span {
  display: block;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td.cell.field_redac_name > span.more.redac-email {
  display: block;
  font-size: 0.8em;
}
td {
  z-index: 0;
}
.value_created,
.value_redac_name,
.value_title {
  position: sticky;
  width: 5em;
  left: 0;
  top: auto;
  z-index: 12;
  background-color: white;
}
b.red {
  color: red;
}
</style>
